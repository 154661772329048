<template>
  <div class="subsidyApplication">
    <!-- 通过弹窗 -->
    <el-dialog title="审批通过" :visible.sync="showy" width="30%" append-to-body :before-close="handleClose">
      <div class="tongyi">
        <div class="topbox">
          <span>审批意见：</span>
          <input type="text" v-model="idea" @input="callApiOnBlur" />
        </div>
        <div class="footerbox">
          <div v-if="showy" class="img">
            <vue-qr ref="qrCode" :text="text" width="200" height="200"></vue-qr>
          </div>

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="showy = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="审批不通过" :visible.sync="onshow" width="30%" append-to-body :before-close="handleClose">
      <!-- 展示上传的图片 -->
      <div class="tuihui">
        <div class="leftbox">退回说明</div>
        <div class="rightbox">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="title">
          </el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitBack">退 回</el-button>
      </span>
    </el-dialog>

    <div class="title" style="display: flex; justify-content: space-between;">
      <h3>种粮大户补贴审核</h3>
      <h4><el-button type="text" @click="goBack"><i class="el-icon-arrow-left"></i>返回</el-button></h4>

    </div>

    <FormQuery ref="formquery" :data="Query" :key="keyForm" @submit="search" @reset="reset">

    </FormQuery>

    <!-- <div class="tabsList">
      <div v-for="(item, index) in tabsList" :key="index" @click="changeTab(index)">
        <span :class="tableIndex == index ? 'tabClass' : ''">
          {{ item.title }}</span>
      </div>
    </div> -->

    <div class="tableBox" v-if="tableIndex == 0">
      <table-page ref="subsidyApplication" :columnsType="columnsType" :data="tableData" :pagination="false"
        :columns="columns" :border="true" @handSelectAll="handSelectAll" @handSelect="handSelect">
        <template slot="applyUser" slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p>{{ scope.row.content }}</p>
            <div slot="reference">
              <span>{{ scope.row.applyUser }}</span>
            </div>
          </el-popover>
        </template>
        <template slot="orderState" slot-scope="scope">
          <el-button size="small" round type="text" @click="details(scope.row,'xq')">详情</el-button>
          <el-button v-if="isShow" size="small" round type="text" @click="toBackOne(scope.row)">退回</el-button>
          <el-button v-if="isShow && scope.row.agree==1" size="small" round type="text"
            @click="details(scope.row,'xg')">修改</el-button>
        </template>
      </table-page>
      <div class="BtnBox" v-if="isShow">
        <div class="inner">
          <el-button style="color: #fff;background-color: #F7C6CC;" round @click="toBack">退回</el-button>
          <el-button style="color: #fff;background-color: #02A7F0;" round @click="toPass">审核通过</el-button>
        </div>
      </div>
    </div>

    <div class="" v-else>
      <span>地区：</span>
      <el-cascader ref="cascader" v-model="MaterialArea" :options="options" :props="prop" :show-all-levels="false"
        @change="handleChange"></el-cascader>
      <div>
        <p>大堰镇黑井村其他材料</p>
        <img src="" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
  import VueQr from "vue-qr";

  import { mapGetters } from "vuex";
  import { getXzqArr } from "@/api/add";
  import { rejectWorkflow } from "@/api/snbt";
  import { Utils } from "@/assets/js/util.js";
  import { zldhbtsqColumn } from "../../tableColumn";
  import { removeEmptyChildren } from "@/js/utils/util";
  import FormQuery from "@/components/form/form-search";
  import TablePage from "@/components/table/ConsolidatedTable";
  import { selectVillageGrowersList, townSelectOtherFiles, redisWorkflow } from "@/api/subsidy";
  const Url = window.globalUrl.api2;

  const Query = [
    {
      name: "companyName",
      label: "申请人",
      componentType: "input",
      placeholder: "请输入",
      width: "180px",
      // dataOptions: [],
    },
    {
      name: "areaCode",
      label: "种粮地点：",
      componentType: "cascader",
      placeholder: "请选择",
      width: "180px",
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
      },
      dataOptions: [],
    },
  ];
  export default {
    components: {
      FormQuery,
      TablePage,
      VueQr,
    },
    data() {
      return {
        Query: Query,
        columns: zldhbtsqColumn,
        loading: false,
        key: "", // 二维码key
        keyForm: 1, //下拉框为请求来的数据时监听会用到
        tableIndex: 0, //地区切换的下标
        options: [],
        MaterialArea: [],
        showy: false,// 审批通过弹窗
        onshow: false, // 审批不通过弹窗
        idea: "", // 审批意见
        title: "", // 退回原因
        selection: [],//选中的数据
        backObj: {},//退回的对象,
        SearchItem: [],   // 搜索条件
        columnsType: {},//列表类型
        isShow: false,
        str: '',
        tableData: [

        ],
        tabsList: [
          {
            title: "补贴核实汇总表",
          },
          {
            title: '其他材料'
          }

        ],
        prop: {
          label: "areaName",
          children: "children",
          value: "id",
          checkStrictly: true,
        }, //省市区选择器
      };
    },
    computed: {
      ...mapGetters(["topSelectAreacode", 'identityObj']),

    },
    mounted() {
      this.selectVillageGrowersList()
      this.getRegions()
      // this.isShow()
    },
    created() {
      var that = this;
      Utils.$off("dbspDetails");
      Utils.$on("dbspDetails", function (e) {
        // console.log("4445411111111111111111111111111111111111111111111111", e.data);
        console.log(JSON.parse(e.data));
        let res = JSON.parse(e.data);

        if (res.isBoolean == true) {
          // console.log('这里执行没有')
          that.showy = false
          that.onshow = false
          that.selectVillageGrowersList()
        }
      });
    },
    methods: {
      async search(params) {
        this.SearchItem.name = params.companyName
        this.SearchItem.areaCode = params.areaCode
          ? params.areaCode[params.areaCode.length - 1]
          : null,
          this.selectVillageGrowersList()
      },
      goBack() {
        this.$router.go(-1); // 返回上一级路由
      },
      reset() {
        this.SearchItem = {}
      },
      // 全选或者反选
      handSelectAll(selection) {
        // console.log(selection, "全选");
        this.selection = selection
      },
      //多选
      handSelect(selection) {
        // console.log(selection, "单选");
        this.selection = selection
      },
      // 关闭弹窗
      handleClose() {
        this.showy = false
        this.onshow = false
        this.idea = "" // 审批意见
        this.title = "" // 退回原因
        this.backObj = {}

      },
      // //批量通过审核
      async toPass() {

        if (!this.selection || this.selection.length === 0) {
          this.$message.warning("请先选择审批的项目");
          return; // 终止执行
        }

        let allTaskIds = []
        this.selection.forEach(item => {
          allTaskIds.push({
            declareId: item.declareId,
            taskId: item.taskId,
          })
        })

        // console.log(allTaskIds,'查看params')
        let res = await redisWorkflow(allTaskIds)
        this.key = res.data;
        this.callApiOnBlur()
        this.showy = true
      },
      toBackOne(row) {
        this.onshow = true
        this.backObj = row
      },
      //点击批量退回
      toBack() {
        if (!this.selection || this.selection.length === 0) {
          this.$message.warning("请先选择退回的项目");
          return; // 终止执行
        }
        this.onshow = true
      },
      //批量退回接口
      submitBack() {

        // console.log(this.backObj,'查看退回原因')
        if (this.backObj && Object.keys(this.backObj).length > 0) {
          let allTaskIds = []
          allTaskIds.push({
            agreeReason: this.title ? this.title : '',
            agreePhoto: this.backObj.sign,
            declareId: this.backObj.declareId,
            taskId: this.backObj.taskId,
          })
          this.reject(allTaskIds);
        } else {
          // console.log(this.selection,'查看退回的对象')
          let allTaskIds = []
          this.selection.forEach(item => {
            allTaskIds.push({
              agreeReason: this.title ? this.title : '',
              agreePhoto: item.sign,
              declareId: item.declareId,
              taskId: item.taskId,
            })
          })
          this.reject(allTaskIds);
        }

      },
      // 拒绝
      async reject(params) {
        let res = await rejectWorkflow(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.handleClose();
          this.selectVillageGrowersList();
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      },
      // 重新生成二维码
      callApiOnBlur() {
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        // 提取所有的 taskIds
        this.text = `${Url}?key=${this.key}/identityId=${identityObj.content.identityId}/agreeReason=${this.idea}`;
        console.log(this.text, '查看this.text')
      },
      //点击进入详情页
      details(row, type) {
        // console.log(row, '查看详情反的row')
        this.$router.push({
          path: "/memberCenter/snbt/snbtbl/components/subsidyApplicationDetails",
          query: { declareId: row.declareId, type: type, declareIdentityId: row.declareIdentityId, str: this.str },
        });
      },
      // 选择其他材料的地址
      handleChange() {
        let node = this.$refs.cascader.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        let areaCode = node.value;
        let address = node.pathLabels.join("");
        this.townSelectOtherFiles(areaCode);
      },
      changeTab(index) {
        this.tableIndex = index;
        if (index == 0) {
        } else {
          this.townSelectOtherFiles(this.topSelectAreacode);
          this.otherAreaList();
        }
      },

      // 种粮大户列表
      async selectVillageGrowersList() {
        if (this.$route.query.str == 'yb') {
          this.columnsType = {
            type: '',
            label: "",
            width: "50",
          }
          this.str = this.$route.query.str
          this.isShow = false
        } else {
          this.columnsType = {
            type: "selection",
            label: "",
            width: "80",
          }
          this.isShow = true
          this.str = this.$route.query.str
        }
        let params = {
          subsidyId: this.$route.query.subsidyId,
          subsidyType: this.$route.query.subsidyType,
          taskKey: this.$route.query.taskKey,
          status: this.$route.query.status,
          ...this.SearchItem,
        }
        let res = await selectVillageGrowersList(params);

        // 调用方法处理种粮大户数据
        let farmersData = this.processFarmersData(
          res.data[0].typeFarmerName,
          res.data[0].typeFarmerNameList
        );


        // console.log(farmersData, "查看种粮大户的数据");

        // 调用方法处理农民专业合作社
        let cooperativesData = this.processFarmersData(
          res.data[0].typeCooperativeName,
          res.data[0].typeCooperativeNameList
        );
        // console.log(cooperativesData, "查看合作社的数据");


        // 调用方法处理家庭农场数据
        let familyFarmersData = this.processFarmersData(
          res.data[0].typeFamilyFarmer,
          res.data[0].typeFamilyFarmerList
        );


        // console.log(familyFarmersData, "查看家庭农场的数据");
        // 调用方法处理土地股份合作社数据
        let landCoopsData = this.processFarmersData(
          res.data[0].typeLandCooperative,
          res.data[0].typeLandCooperativeList
        );



        //调用方法处理农业产业化龙头企业数据
        let leadingEnterpriseData = this.processFarmersData(
          res.data[0].typeIndustrialEnterprise,
          res.data[0].typeIndustrialEnterpriseList
        );

        //村集体经济组织数据
        let typeIndustrialEnterpriseData = this.processFarmersData(
          res.data[0].typeVillageName,
          res.data[0].typeVillageNameList
        );

        //其他
        let othersData = this.processFarmersData(
          res.data[0].others,
          res.data[0].othersList
        );
        this.tableData = [
          ...farmersData,
          ...cooperativesData,
          ...familyFarmersData,
          ...landCoopsData,
          ...leadingEnterpriseData,
          ...typeIndustrialEnterpriseData,
          ...othersData
        ]
        console.log(this.tableData, '查看获取的table列表是什么')
      },
      // 转换数据格式
      processFarmersData(typeName, list) {
        if (!list) return []; // 处理空列表的情况
        return list.map((item, index) => {
          return {
            // [typeName]: item[typeName],
            typeFarmerName: typeName,
            serialNumber: index + 1,
            sum1: item.contractArea + item.rentalArea,
            sum2: item.riceArea + item.cornArea + item.wheatArea,
            ...item,
          };
        });
      },
      // 其他材料
      async townSelectOtherFiles(areaCode) {
        let params = {
          areaCode: areaCode,
        };
        let res = await townSelectOtherFiles();
      },
      // 获取种粮地点
      async getRegions() {
        let areaCode = JSON.parse(localStorage.getItem("identityObj")).content.areaCode
          ;
        console.log(areaCode, '查看areaCode')
        let res = await getXzqArr({
          // level: 3,
          parentCode: areaCode,
        });
        if (res.code == 0) {
          let hasRegion = setInterval(() => {
            this.Query[1].dataOptions = removeEmptyChildren(res.data, "children");
            console.log(this.Query[1].dataOptions);
            this.keyForm++;
            clearInterval(hasRegion);
          }, 100);
        }
      },
      // 选择其他材料的地区
      async otherAreaList() {
        let res = await getXzqArr({
          level: 3,
        });
        this.options = res.data;
      },
    },
  };
</script>

<style lang="less" scoped>
  .subsidyApplication {
    margin-left: 50px;

    .tabsList {
      display: flex;
      padding: 20px 0px;

      span {
        margin-right: 20px;
        border: solid 1px black;
      }
    }

    .tabClass {
      background: #02a7f0;
    }
  }

  .BtnBox {
    width: 100%;
    display: flex;
    justify-content: center;

    .inner {
      button {
        margin-right: 20px;
      }
    }
  }

  .tongyi {
    box-sizing: border-box;

    .topbox {
      span {
        width: 30%;
        font-size: 20px;
        // font-weight: 400;
        color: #000;
      }

      input {
        width: 60%;
        font-size: 20px;
      }
    }

    .footerbox {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .tuihui {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #000;

    .leftbox {
      width: 20%;
      height: 200px;
      text-align: center;
      line-height: 200px;
      font-size: 22px;
      border-right: 1px solid #000;
    }

    .rightbox {
      width: 80%;
      // height: 200px;

      // border-right: 1px solid #000;
    }
  }
</style>